import React, { useState } from "react";
import { Modal, Form, Input, notification } from "antd";
import styled, { createGlobalStyle, keyframes, css } from "styled-components";
import { Button } from "../../common/Button";
import { useAuth } from "../../context/AuthContext";
import {
    LoginApi
} from "../../client";
import { message } from 'antd';
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from 'react-router-dom';
const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Motiva Sans Light';
        src: url('/public/fonts/Motiva-Sans-Light.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Motiva Sans Bold';
        src: url('/public/fonts/Motiva-Sans-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
    }

    body {
        font-family: 'Motiva Sans Light';
    }
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const SignUpContainer = styled.div<{ fadeType: 'in' | 'out' }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-family: 'Motiva Sans Light';
    animation: ${props => props.fadeType === 'in' ? fadeIn : fadeOut} 0.5s ease;
`;

const StyledInput = styled(Input)`
    border: 1px solid #edf3f5;
    border-radius: 24px;
    padding: 8px 16px;
    font-size: 1.2rem;
    font-family: 'Motiva Sans Light';
`;

const StyledInputPassword = styled(Input.Password)`
    border: 1px solid #edf3f5;
    border-radius: 24px;
    padding: 8px 16px;
    font-size: 1.2rem;
    font-family: 'Motiva Sans Light';
`;

const TextContainer = styled.div`
    margin-top: 10px;
    text-align: center;
    font-size: 1rem;
    color: #000000;
    div {
        font-family: 'Motiva Sans Light';
    }

    a {
        color: #FF5400;
        cursor: pointer;
        text-decoration: none;
        transition: color 0.3s ease, transform 0.2s ease;
        font-family: 'Motiva Sans Light';
        &:hover {
            color: #FF8540;
        }

        &:active {
            transform: scale(0.95);
        }
    }

    .terms {
        margin-top: 10px;
        font-size: 1rem;
        color: #000000;
        font-family: 'Motiva Sans Light';
    }
`;

const StyledModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 24px;
        background-color: #f0f0f0;
        font-size: 1.2rem;
        font-family: "'Motiva Sans Light'";
    }

    .ant-modal-header {
        text-align: center;
        background-color: #f0f0f0;
        border-radius: 24px;
        font-size: 1.2rem;
        font-family: 'Motiva Sans Light';
    }

    .ant-modal-title {
        width: 100%;
        font-size: 1.5rem;
        font-family: 'Motiva Sans Bold';
    }

    @media (max-width: 768px) {
        width: 80% !important;
        max-width: none !important;
        height: auto;
    }

    @media (min-width: 769px) {
        width: 400px !important;
    }
`;

const ErrorText = styled.div`
    color: red;
    font-size: 1rem;
    margin-bottom: 10px;
    text-align: center;
`;

const SignIn: React.FC<{ visible: boolean; onClose: () => void; onSwitch: () => void; onForget: () => void }> = ({ visible, onClose, onSwitch, onForget }) => {
    const { t } = useTranslation();
    const [fadeType, setFadeType] = useState<'in' | 'out'>('in');
    const { setAccessToken, apiClient } = useAuth();
    const [loginError, setLoginError] = useState<string | null>(null);
    const loginApi = new LoginApi(apiClient);
    const location = useLocation();
    const history = useHistory();
    const handleLogin = async (values: any) => {
        const { email, password } = values;

        try {
            // 将邮箱转换为小写
            const lowerCaseEmail = email.toLowerCase();

            const response = await loginApi.loginLoginAccessToken(lowerCaseEmail, password);
            const { access_token } = response.data;
            setAccessToken(access_token);
            message.success('Login successful');
            onClose();

            // 检查当前路径是否为首页
            if (location.pathname !== '/' && location.pathname !== '/home') {
                history.push('/home');
            }
        } catch (error) {
            console.error(error);
            setLoginError(t('login_failed'));
        }
    };
    // const handleLogin = async (values: any) => {
    //     const { email, password } = values;
    
    //     // 假数据
    //     const fakeResponse = {
    //         access_token: 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiSm9obiIsImFkbWluIjp0cnVlLCJleHAiOjE4NTgwMDMyMDB9.ljY4U4zKw3sABvQE6h8EAEqZs1430RQPr79bBSX0QF6krT4rlZ1V0owSt8vpAWBkAm3FJMhsYt2odBgaZQOnZw.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTczMTExOTg2OSwiaWF0IjoxNzMxMTE5ODY5fQ.R6qdruEPjxosUG3FgdR05UXvP4RWZBCcmRSp420anao',
    //     };
    
    //     // 绕过验证逻辑，直接设置假数据
    //     setAccessToken(fakeResponse.access_token);
    //     message.success('Login successful');
    //     onClose();
    
    //     // 如果当前路径不是首页，则重定向到首页
    //     if (location.pathname !== '/' && location.pathname !== '/home') {
    //         history.push('/home');
    //     }
    // };

    const handleInputChange = () => {
        setLoginError(null);
    };

    return (
        <StyledModal
            title={t('sign_in')}
            visible={visible}
            onCancel={onClose}
            centered
            footer={null}
        >
            <SignUpContainer fadeType={fadeType}>
                <Form
                    name="sign_in"
                    initialValues={{ remember: true }}
                    onFinish={handleLogin}
                    onFinishFailed={(errorInfo) => console.log("Failed:", errorInfo)}
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: t('please_input_your_email') }]}
                    >
                        <StyledInput placeholder={t('email')} onChange={handleInputChange} />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: t('please_input_your_password') }]}
                    >
                        <StyledInputPassword placeholder={t('password')} onChange={handleInputChange} />
                    </Form.Item>
                    {loginError && <ErrorText>{loginError}</ErrorText>}
                    <TextContainer>
                        <div className="terms">
                            {t('by_continuing')} <a href="/terms" target="_blank"
                                                    rel="noopener noreferrer">{t('terms_conditions')}</a>

                        </div>
                    </TextContainer>
                    <Form.Item>
                        <Button width="100%">{t('sign_in_button')}</Button>
                    </Form.Item>
                    <TextContainer>
                        <div>
                            <a onClick={onForget}>{t('forgot_password')}</a>
                        </div>
                        <div>
                            {t('dont_have_account')} <a onClick={onSwitch} >{t('sign_up_button')}</a>
                        </div>
                    </TextContainer>
                </Form>
            </SignUpContainer>
        </StyledModal>
    );
};

export default SignIn;
