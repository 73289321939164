import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import CookieConsent from "react-cookie-consent";
import Router from "./router";
import i18n from "./i18n";
import { createGlobalStyle } from 'styled-components';
import { AuthProvider } from './context/AuthContext';


const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Motiva Sans Light';
        src: url('/fonts/Motiva-Sans-Light.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Motiva Sans Bold';
        src: url('/fonts/Motiva-Sans-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
    }

    body {
        font-family: 'Motiva Sans Light';
    }
`;


const App = () => (
    <AuthProvider>
        <BrowserRouter>
            <I18nextProvider i18n={i18n}>
                <GlobalStyle />
                <Router />
                <CookieConsent
                    location="bottom"
                    buttonText="Accept Cookies"
                    cookieName="myAwesomeCookieName"
                    style={{background: "#F0F0F0", color: "#000000"}}
                    buttonStyle={{
                        color: "#F0F0F0",
                        backgroundColor: "#FF5400",
                        border: "1px solid #F0F0F0",
                        borderRadius: "24px",
                        padding: "8px 16px",
                        cursor: "pointer"
                    }}
                    expires={150}
                >
                    We use cookies to improve your experience on our site. By accepting, you agree to our use of
                    cookies.{" "}

                    <a href="/terms" target="_blank"
                       rel="noopener noreferrer" style={{color: "#FF5400"}}>Learn more</a>
                </CookieConsent>
            </I18nextProvider>
        </BrowserRouter>
    </AuthProvider>
);

async function prepare() {
if (process.env.NODE_ENV === 'development') {
        const { worker } = await import('./mocks/browser');
        await worker.start({
            onUnhandledRequest: 'bypass', 
        });
    }
}

prepare().then(() => {
    ReactDOM.render(<App />, document.getElementById("root"));
});