import React, { useState } from "react";
import { Modal, Form, Input, notification,message } from "antd";
import styled from "styled-components";
import { Button } from "../../common/Button";
import { useTranslation } from "react-i18next";
import {LoginApi} from "../../client";
import {useAuth} from "../../context/AuthContext";

const StyledModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 24px;
        background-color: #f0f0f0;
        font-size: 1.2rem;
        font-family: "'Motiva Sans Light'";
    }

    .ant-modal-header {
        text-align: center;
        background-color: #f0f0f0;
        border-radius: 24px;
        font-size: 1.2rem;
        font-family: 'Motiva Sans Light';
    }

    .ant-modal-title {
        width: 100%;
        font-size: 1.5rem;
        font-family: 'Motiva Sans Bold';
    }

    @media (max-width: 768px) {
        width: 80% !important;
        max-width: none !important;
        height: auto;
    }

    @media (min-width: 769px) {
        width: 400px !important;
    }
`;

const StyledInput = styled(Input)`
    border: 1px solid #edf3f5;
    border-radius: 24px;
    padding: 8px 16px;
    font-size: 1.2rem;
    font-family: 'Motiva Sans Light';
`;

const Forget: React.FC<{ visible: boolean; onClose: () => void }> = ({ visible, onClose }) => {
    const { t } = useTranslation();
    const { setAccessToken, apiClient } = useAuth();
    const loginApi = new LoginApi(apiClient);
    const [loading, setLoading] = useState(false);
    const openNotification = (email: any) => {
        notification.open({
            message: t('Password Recovery'),
            description: (
                <>
                    {t('Check your email')}: {email}
                    <br />
                    {t("We've sent you a reset link.")}
                </>
            ),
            icon: <img src="/not.svg" alt="Notification Icon" style={{ width: 24, height: 24 }} />,
            style: { fontFamily: 'Roboto' },
            duration:7,
        });
    };

    const recovery_yes = async (values: any) => {
        setLoading(true);
        try {
            
            const email = values.email.toLowerCase();

            
            await loginApi.loginRecoverPassword(email);

            setLoading(false); 
            openNotification(email);

            
            onClose();
        } catch (error) {

            message.error(t('Failed to send recovery email. Please try again later.'));
        }finally {
            setLoading(false); 
        }
    };


    return (
        <StyledModal
            title={t('Password Recovery')}
            visible={visible}
            onCancel={onClose}
            centered
            footer={null}
        >
            <div style={{ marginBottom: '10px', fontSize: '1rem', color: '#828282', fontFamily: 'Motiva Sans Light', textAlign: 'center' }}>
                {t('A password recovery email will be sent to the registered account.')}
            </div>
            <Form
                name="forgot_password"
                initialValues={{ remember: true }}
                onFinish={(values) => recovery_yes(values)}
                onFinishFailed={(errorInfo) => console.log("Failed:", errorInfo)}
            >
                <Form.Item
                    name="email"
                    rules={[
                        { required: true, message: t("Please input your email!") },
                        { type: 'email', message: t("The input is not valid E-mail!") }
                    ]}
                >
                    <StyledInput placeholder={t('Email')} />
                </Form.Item>
                <Form.Item>
                    <Button width="100%" loading={loading}>{t('Continue')}</Button>
                </Form.Item>
            </Form>
        </StyledModal>
    );
};

export default Forget;
