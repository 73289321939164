import React, { useState } from "react";
import { Modal, Form, Input, message, notification } from "antd";
import styled, { createGlobalStyle } from "styled-components";
import { Button } from "../../common/Button";
import { useTranslation } from 'react-i18next';
import { useAuth } from "../../context/AuthContext";
import { UsersApi } from "../../client";

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'Motiva Sans Light';
        src: url('/public/fonts/Motiva-Sans-Light.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'Motiva Sans Bold';
        src: url('/public/fonts/Motiva-Sans-Bold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
    }

    body {
        font-family: 'Motiva Sans Light';
    }
`;

const openNotification = (email: string, t: any) => {
    notification.open({
        message: 'Registration Verification',
        description: (
            <>
            We've sent the verification link to your email {email}.
            <br />
            Please check and confirm the registration.
            <br />
            If you don't see the email, please check your spam or junk mailbox.
        </>
        ),
        icon: <img src="/not.svg" alt="Notification Icon" style={{ width: 24, height: 24 }} />,
        style: { fontFamily: 'Roboto' },
        duration:7,
    });
};

const SignUpContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-family: 'Motiva Sans Light';
`;

const StyledInput = styled(Input)`
    border: 1px solid #edf3f5;
    border-radius: 24px;
    padding: 8px 16px;
    font-size: 1.2rem;
    font-family: 'Motiva Sans Light';
`;

const StyledInputPassword = styled(Input.Password)`
    border: 1px solid #edf3f5;
    border-radius: 24px;
    padding: 8px 16px;
    font-size: 1.2rem;
    font-family: 'Motiva Sans Light';
`;

const TextContainer = styled.div`
    margin-top: 10px;
    text-align: center;
    font-size: 1rem;
    color: #000000;
    div{
        font-family: 'Motiva Sans Light';
    }

    a {
        color: #FF5400;
        cursor: pointer;
        text-decoration: none;
        transition: color 0.3s ease, transform 0.2s ease;
        font-family: 'Motiva Sans Light';
        &:hover {
            color: #FF8540;
        }

        &:active {
            transform: scale(0.95);
        }
    }

    .terms {
        margin-top: 10px;
        font-size: 1rem;
        color: #000000;
        font-family: 'Motiva Sans Light';
    }
`;

const StyledModal = styled(Modal)`
    .ant-modal-content {
        border-radius: 24px;
        background-color: #f0f0f0;
        font-size: 1.2rem;
        font-family: "'Motiva Sans Light'";
    }

    .ant-modal-header {
        text-align: center;
        background-color: #f0f0f0;
        border-radius: 24px;
        font-size: 1.2rem;
        font-family: 'Motiva Sans Light';
    }

    .ant-modal-title {
        width: 100%;
        font-size: 1.5rem;

        font-family: 'Motiva Sans Bold';
    }

    @media (max-width: 768px) {
        width: 80% !important;
        max-width: none !important;
        height: auto;
    }

    @media (min-width: 769px) {
        width: 400px !important;
    }
`;

const SignUp: React.FC<{ visible: boolean; onClose: () => void; onSwitch: () => void }> = ({ visible, onClose, onSwitch }) => {
    const { t } = useTranslation();
    const { apiClient } = useAuth();

    const [loading, setLoading] = useState(false);

    const onFinish = async (values: any) => {
        const { email, password } = values;
        const usersApi = new UsersApi(apiClient);
        setLoading(true); // 开始加载状态
        try {
            const lowerCaseEmail = email.toLowerCase();
            await usersApi.usersRegisterUser({
                email: lowerCaseEmail,
                password,
                is_premium: false,
            });
            openNotification(email, t);
            onClose();
        } catch (error: any) {
            
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data.detail;
                if (errorMessage === "The user with this email already exists in the system.") {
                    message.error("This email is already registered.");
                } else {
                    message.error("Registration failed. Please try again later.");
                }
            } else {
                message.error("Registration failed. Please try again later.");
            }
        } finally {
            setLoading(false); // 请求完成，结束加载状态
        }
    };
    

    return (
        <StyledModal
            title={t('create_account')}
            visible={visible}
            onCancel={onClose}
            centered
            footer={null}
        >
            <SignUpContainer>
                <Form
                    name="sign_up"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={(errorInfo) => console.log("Failed:", errorInfo)}
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: t('please_input_email') }]}
                    >
                        <StyledInput placeholder={t('email')} />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: t('please_input_password') }]}
                    >
                        <StyledInputPassword placeholder={t('password')} />
                    </Form.Item>
                    <Form.Item
                        name="re-password"
                        dependencies={['password']}
                        rules={[
                            { required: true, message: t('please_input_repassword') },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('The passwords entered do not match.')));
                                },
                            }),
                        ]}
                    >
                        <StyledInputPassword placeholder={t('repassword')} />
                    </Form.Item>
                    <TextContainer>
                        <div className="terms">
                            {t('by_continuing')} <a href="/terms" target="_blank"
                                                    rel="noopener noreferrer">{t('terms_conditions')}</a>

                        </div>
                    </TextContainer>
                    <Form.Item>
                    <Button width="100%" loading={loading}>
                            {t('create_account_button')}
                        </Button>
                    </Form.Item>
                    <TextContainer>
                        <div>
                            {t('already_have_account')} <a onClick={onSwitch}>{t('sign_in')}</a>
                        </div>
                    </TextContainer>
                </Form>
            </SignUpContainer>
        </StyledModal>
    );
};

export default SignUp;
