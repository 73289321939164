/* tslint:disable */
/* eslint-disable */
/**
 * Full Stack FastAPI Project
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface CreditCreate
 */
export interface CreditCreate {
    /**
     * 
     * @type {number}
     * @memberof CreditCreate
     */
    'user_id': number;
    /**
     * 
     * @type {number}
     * @memberof CreditCreate
     */
    'transaction_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreditCreate
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof CreditCreate
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface NewPassword
 */
export interface NewPassword {
    /**
     * 
     * @type {string}
     * @memberof NewPassword
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof NewPassword
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface PRReportRequest
 */
export interface PRReportRequest {
    /**
     * 
     * @type {number}
     * @memberof PRReportRequest
     */
    'approval_prob': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PRReportRequest
     */
    'approval_ci': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PRReportRequest
     */
    'processing_time': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PRReportRequest
     */
    'processing_ci': Array<string>;
}
/**
 * 
 * @export
 * @interface PredictionData
 */
export interface PredictionData {
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'nationality': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'passType': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'maritalStatus': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'age': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'yearsInSG': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'educationLevel': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'schoolType': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'studyType': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'studyInSG': string;
    /**
     * 
     * @type {boolean}
     * @memberof PredictionData
     */
    'terms': boolean;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'familyMemberPRCitizen': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'applyAsFamily': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'employmentStatus': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'employerBusinessNature': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'employerOwnership': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'employerSize': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'yearsWithEmployer': string;
    /**
     * 
     * @type {string}
     * @memberof PredictionData
     */
    'monthlySalary': string;
}
/**
 * 
 * @export
 * @interface RecordResponse
 */
export interface RecordResponse {
    /**
     * 
     * @type {number}
     * @memberof RecordResponse
     */
    'user_id': number;
    /**
     * 
     * @type {number}
     * @memberof RecordResponse
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof RecordResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof RecordResponse
     */
    'record_time': string;
    /**
     * 
     * @type {number}
     * @memberof RecordResponse
     */
    'balance_before': number;
    /**
     * 
     * @type {number}
     * @memberof RecordResponse
     */
    'balance_after': number;
}
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'token_type'?: string;
}
/**
 * 
 * @export
 * @interface TransactionCreate
 */
export interface TransactionCreate {
    /**
     * 
     * @type {number}
     * @memberof TransactionCreate
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof TransactionCreate
     */
    'type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionCreate
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof TransactionCreate
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionCreate
     */
    'transaction_time': string;
}
/**
 * 
 * @export
 * @interface UpdatePassword
 */
export interface UpdatePassword {
    /**
     * 
     * @type {string}
     * @memberof UpdatePassword
     */
    'current_password': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePassword
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreate
     */
    'is_premium'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface UserPublic
 */
export interface UserPublic {
    /**
     * 
     * @type {number}
     * @memberof UserPublic
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserPublic
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof UserPublic
     */
    'credits'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPublic
     */
    'supercredits'?: number;
}
/**
 * 
 * @export
 * @interface UserUpdateMe
 */
export interface UserUpdateMe {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateMe
     */
    'full_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateMe
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface UsersPublic
 */
export interface UsersPublic {
    /**
     * 
     * @type {Array<UserPublic>}
     * @memberof UsersPublic
     */
    'data': Array<UserPublic>;
    /**
     * 
     * @type {number}
     * @memberof UsersPublic
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * CreditsApi - axios parameter creator
 * @export
 */
export const CreditsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Transaction
         * @param {CreditCreate} creditCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditsCreateTransaction: async (creditCreate: CreditCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creditCreate' is not null or undefined
            assertParamExists('creditsCreateTransaction', 'creditCreate', creditCreate)
            const localVarPath = `/api/v1/credit/create-creditrecord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(creditCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Credits Consume
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditsCreditsConsume: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/credit/user-consume-credits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Credit Records
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditsGetUserCreditRecords: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/credit/user-credit-records`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Supercredits Consume
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditsSupercreditsConsume: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/credit/user-consume-supercredits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CreditsApi - functional programming interface
 * @export
 */
export const CreditsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CreditsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Transaction
         * @param {CreditCreate} creditCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditsCreateTransaction(creditCreate: CreditCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditsCreateTransaction(creditCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CreditsApi.creditsCreateTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Credits Consume
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditsCreditsConsume(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditsCreditsConsume(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CreditsApi.creditsCreditsConsume']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get User Credit Records
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditsGetUserCreditRecords(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecordResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditsGetUserCreditRecords(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CreditsApi.creditsGetUserCreditRecords']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Supercredits Consume
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creditsSupercreditsConsume(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creditsSupercreditsConsume(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CreditsApi.creditsSupercreditsConsume']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CreditsApi - factory interface
 * @export
 */
export const CreditsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CreditsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Transaction
         * @param {CreditCreate} creditCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditsCreateTransaction(creditCreate: CreditCreate, options?: any): AxiosPromise<any> {
            return localVarFp.creditsCreateTransaction(creditCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Credits Consume
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditsCreditsConsume(options?: any): AxiosPromise<Message> {
            return localVarFp.creditsCreditsConsume(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Credit Records
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditsGetUserCreditRecords(options?: any): AxiosPromise<Array<RecordResponse>> {
            return localVarFp.creditsGetUserCreditRecords(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Supercredits Consume
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creditsSupercreditsConsume(options?: any): AxiosPromise<Message> {
            return localVarFp.creditsSupercreditsConsume(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CreditsApi - object-oriented interface
 * @export
 * @class CreditsApi
 * @extends {BaseAPI}
 */
export class CreditsApi extends BaseAPI {
    /**
     * 
     * @summary Create Transaction
     * @param {CreditCreate} creditCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsApi
     */
    public creditsCreateTransaction(creditCreate: CreditCreate, options?: RawAxiosRequestConfig) {
        return CreditsApiFp(this.configuration).creditsCreateTransaction(creditCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Credits Consume
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsApi
     */
    public creditsCreditsConsume(options?: RawAxiosRequestConfig) {
        return CreditsApiFp(this.configuration).creditsCreditsConsume(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Credit Records
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsApi
     */
    public creditsGetUserCreditRecords(options?: RawAxiosRequestConfig) {
        return CreditsApiFp(this.configuration).creditsGetUserCreditRecords(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Supercredits Consume
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CreditsApi
     */
    public creditsSupercreditsConsume(options?: RawAxiosRequestConfig) {
        return CreditsApiFp(this.configuration).creditsSupercreditsConsume(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * OAuth2 compatible token login, get an access token for future requests
         * @summary Login Access Token
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginLoginAccessToken: async (username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('loginLoginAccessToken', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginLoginAccessToken', 'password', password)
            const localVarPath = `/api/v1/login/access-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Password Recovery
         * @summary Recover Password
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginRecoverPassword: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('loginRecoverPassword', 'email', email)
            const localVarPath = `/api/v1/password-recovery/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * HTML Content for Password Recovery
         * @summary Recover Password Html Content
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginRecoverPasswordHtmlContent: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('loginRecoverPasswordHtmlContent', 'email', email)
            const localVarPath = `/api/v1/password-recovery-html-content/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset password
         * @summary Reset Password
         * @param {NewPassword} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginResetPassword: async (newPassword: NewPassword, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'newPassword' is not null or undefined
            assertParamExists('loginResetPassword', 'newPassword', newPassword)
            const localVarPath = `/api/v1/reset-password/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Test access token
         * @summary Test Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginTestToken: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/login/test-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * OAuth2 compatible token login, get an access token for future requests
         * @summary Login Access Token
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginLoginAccessToken(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginLoginAccessToken(username, password, grantType, scope, clientId, clientSecret, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.loginLoginAccessToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Password Recovery
         * @summary Recover Password
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginRecoverPassword(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginRecoverPassword(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.loginRecoverPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * HTML Content for Password Recovery
         * @summary Recover Password Html Content
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginRecoverPasswordHtmlContent(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginRecoverPasswordHtmlContent(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.loginRecoverPasswordHtmlContent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Reset password
         * @summary Reset Password
         * @param {NewPassword} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginResetPassword(newPassword: NewPassword, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginResetPassword(newPassword, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.loginResetPassword']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Test access token
         * @summary Test Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginTestToken(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginTestToken(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LoginApi.loginTestToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * OAuth2 compatible token login, get an access token for future requests
         * @summary Login Access Token
         * @param {string} username 
         * @param {string} password 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginLoginAccessToken(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: any): AxiosPromise<Token> {
            return localVarFp.loginLoginAccessToken(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Password Recovery
         * @summary Recover Password
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginRecoverPassword(email: string, options?: any): AxiosPromise<Message> {
            return localVarFp.loginRecoverPassword(email, options).then((request) => request(axios, basePath));
        },
        /**
         * HTML Content for Password Recovery
         * @summary Recover Password Html Content
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginRecoverPasswordHtmlContent(email: string, options?: any): AxiosPromise<string> {
            return localVarFp.loginRecoverPasswordHtmlContent(email, options).then((request) => request(axios, basePath));
        },
        /**
         * Reset password
         * @summary Reset Password
         * @param {NewPassword} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginResetPassword(newPassword: NewPassword, options?: any): AxiosPromise<Message> {
            return localVarFp.loginResetPassword(newPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * Test access token
         * @summary Test Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginTestToken(options?: any): AxiosPromise<UserPublic> {
            return localVarFp.loginTestToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * OAuth2 compatible token login, get an access token for future requests
     * @summary Login Access Token
     * @param {string} username 
     * @param {string} password 
     * @param {string | null} [grantType] 
     * @param {string} [scope] 
     * @param {string | null} [clientId] 
     * @param {string | null} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public loginLoginAccessToken(username: string, password: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).loginLoginAccessToken(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Password Recovery
     * @summary Recover Password
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public loginRecoverPassword(email: string, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).loginRecoverPassword(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * HTML Content for Password Recovery
     * @summary Recover Password Html Content
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public loginRecoverPasswordHtmlContent(email: string, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).loginRecoverPasswordHtmlContent(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reset password
     * @summary Reset Password
     * @param {NewPassword} newPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public loginResetPassword(newPassword: NewPassword, options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).loginResetPassword(newPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Test access token
     * @summary Test Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public loginTestToken(options?: RawAxiosRequestConfig) {
        return LoginApiFp(this.configuration).loginTestToken(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ModelApi - axios parameter creator
 * @export
 */
export const ModelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Analyze Data
         * @param {PredictionData} predictionData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelAnalyzeData: async (predictionData: PredictionData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'predictionData' is not null or undefined
            assertParamExists('modelAnalyzeData', 'predictionData', predictionData)
            const localVarPath = `/api/v1/model/analyze/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(predictionData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make Prediction
         * @param {PredictionData} predictionData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelMakePrediction: async (predictionData: PredictionData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'predictionData' is not null or undefined
            assertParamExists('modelMakePrediction', 'predictionData', predictionData)
            const localVarPath = `/api/v1/model/predict/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(predictionData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelApi - functional programming interface
 * @export
 */
export const ModelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Analyze Data
         * @param {PredictionData} predictionData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelAnalyzeData(predictionData: PredictionData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelAnalyzeData(predictionData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModelApi.modelAnalyzeData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Make Prediction
         * @param {PredictionData} predictionData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelMakePrediction(predictionData: PredictionData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelMakePrediction(predictionData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ModelApi.modelMakePrediction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ModelApi - factory interface
 * @export
 */
export const ModelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModelApiFp(configuration)
    return {
        /**
         * 
         * @summary Analyze Data
         * @param {PredictionData} predictionData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelAnalyzeData(predictionData: PredictionData, options?: any): AxiosPromise<void> {
            return localVarFp.modelAnalyzeData(predictionData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make Prediction
         * @param {PredictionData} predictionData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelMakePrediction(predictionData: PredictionData, options?: any): AxiosPromise<any> {
            return localVarFp.modelMakePrediction(predictionData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModelApi - object-oriented interface
 * @export
 * @class ModelApi
 * @extends {BaseAPI}
 */
export class ModelApi extends BaseAPI {
    /**
     * 
     * @summary Analyze Data
     * @param {PredictionData} predictionData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelApi
     */
    public modelAnalyzeData(predictionData: PredictionData, options?: RawAxiosRequestConfig) {
        return ModelApiFp(this.configuration).modelAnalyzeData(predictionData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make Prediction
     * @param {PredictionData} predictionData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelApi
     */
    public modelMakePrediction(predictionData: PredictionData, options?: RawAxiosRequestConfig) {
        return ModelApiFp(this.configuration).modelMakePrediction(predictionData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 创建一个 Stripe Checkout Session，生成支付链接。
         * @summary Create Checkout Session 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentCreateCheckoutSession1: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/payment/create-checkout-session_1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 创建一个 Stripe Checkout Session，生成支付链接。
         * @summary Create Checkout Session 2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentCreateCheckoutSession2: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/payment/create-checkout-session_2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stripe Webhook 处理函数
         * @summary Stripe Webhook
         * @param {string} [stripeSignature] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentStripeWebhook: async (stripeSignature?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/payment/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stripeSignature != null) {
                localVarHeaderParameter['stripe-signature'] = String(stripeSignature);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 创建一个 Stripe Checkout Session，生成支付链接。
         * @summary Create Checkout Session 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentCreateCheckoutSession1(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentCreateCheckoutSession1(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentApi.paymentCreateCheckoutSession1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 创建一个 Stripe Checkout Session，生成支付链接。
         * @summary Create Checkout Session 2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentCreateCheckoutSession2(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentCreateCheckoutSession2(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentApi.paymentCreateCheckoutSession2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Stripe Webhook 处理函数
         * @summary Stripe Webhook
         * @param {string} [stripeSignature] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentStripeWebhook(stripeSignature?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentStripeWebhook(stripeSignature, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentApi.paymentStripeWebhook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 创建一个 Stripe Checkout Session，生成支付链接。
         * @summary Create Checkout Session 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentCreateCheckoutSession1(options?: any): AxiosPromise<any> {
            return localVarFp.paymentCreateCheckoutSession1(options).then((request) => request(axios, basePath));
        },
        /**
         * 创建一个 Stripe Checkout Session，生成支付链接。
         * @summary Create Checkout Session 2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentCreateCheckoutSession2(options?: any): AxiosPromise<any> {
            return localVarFp.paymentCreateCheckoutSession2(options).then((request) => request(axios, basePath));
        },
        /**
         * Stripe Webhook 处理函数
         * @summary Stripe Webhook
         * @param {string} [stripeSignature] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentStripeWebhook(stripeSignature?: string, options?: any): AxiosPromise<any> {
            return localVarFp.paymentStripeWebhook(stripeSignature, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 创建一个 Stripe Checkout Session，生成支付链接。
     * @summary Create Checkout Session 1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentCreateCheckoutSession1(options?: RawAxiosRequestConfig) {
        return PaymentApiFp(this.configuration).paymentCreateCheckoutSession1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 创建一个 Stripe Checkout Session，生成支付链接。
     * @summary Create Checkout Session 2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentCreateCheckoutSession2(options?: RawAxiosRequestConfig) {
        return PaymentApiFp(this.configuration).paymentCreateCheckoutSession2(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stripe Webhook 处理函数
     * @summary Stripe Webhook
     * @param {string} [stripeSignature] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public paymentStripeWebhook(stripeSignature?: string, options?: RawAxiosRequestConfig) {
        return PaymentApiFp(this.configuration).paymentStripeWebhook(stripeSignature, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Test the creation of a new transaction and insert a record into the Transaction Table.  Parameters: transaction_data (TransactionCreate): The data required to create a transaction.  Returns: Any: The newly created transaction object or error details.
         * @summary Test Create Transaction
         * @param {TransactionCreate} transactionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsTestCreateTransaction: async (transactionCreate: TransactionCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionCreate' is not null or undefined
            assertParamExists('transactionsTestCreateTransaction', 'transactionCreate', transactionCreate)
            const localVarPath = `/api/v1/transaction/create-transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transactionCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Test the creation of a new transaction and insert a record into the Transaction Table.  Parameters: transaction_data (TransactionCreate): The data required to create a transaction.  Returns: Any: The newly created transaction object or error details.
         * @summary Test Create Transaction
         * @param {TransactionCreate} transactionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionsTestCreateTransaction(transactionCreate: TransactionCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.transactionsTestCreateTransaction(transactionCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionsApi.transactionsTestCreateTransaction']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionsApiFp(configuration)
    return {
        /**
         * Test the creation of a new transaction and insert a record into the Transaction Table.  Parameters: transaction_data (TransactionCreate): The data required to create a transaction.  Returns: Any: The newly created transaction object or error details.
         * @summary Test Create Transaction
         * @param {TransactionCreate} transactionCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionsTestCreateTransaction(transactionCreate: TransactionCreate, options?: any): AxiosPromise<any> {
            return localVarFp.transactionsTestCreateTransaction(transactionCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI {
    /**
     * Test the creation of a new transaction and insert a record into the Transaction Table.  Parameters: transaction_data (TransactionCreate): The data required to create a transaction.  Returns: Any: The newly created transaction object or error details.
     * @summary Test Create Transaction
     * @param {TransactionCreate} transactionCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsApi
     */
    public transactionsTestCreateTransaction(transactionCreate: TransactionCreate, options?: RawAxiosRequestConfig) {
        return TransactionsApiFp(this.configuration).transactionsTestCreateTransaction(transactionCreate, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generate PR report PDF and send it to the user\'s email.
         * @summary Generate Pr Report
         * @param {PRReportRequest} pRReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGeneratePrReport: async (pRReportRequest: PRReportRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pRReportRequest' is not null or undefined
            assertParamExists('usersGeneratePrReport', 'pRReportRequest', pRReportRequest)
            const localVarPath = `/api/v1/users/generate-pr-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pRReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current user.
         * @summary Read User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersReadUserMe: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve users.
         * @summary Read Users
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersReadUsers: async (skip?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send email confirmation link to create a new user.
         * @summary Register User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRegisterUser: async (userCreate: UserCreate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreate' is not null or undefined
            assertParamExists('usersRegisterUser', 'userCreate', userCreate)
            const localVarPath = `/api/v1/users/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate PR report PDF and return it directly.
         * @summary Send Pr1
         * @param {PRReportRequest} pRReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSendPr1: async (pRReportRequest: PRReportRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pRReportRequest' is not null or undefined
            assertParamExists('usersSendPr1', 'pRReportRequest', pRReportRequest)
            const localVarPath = `/api/v1/users/send_pr1`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pRReportRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receive a PR report PDF from the frontend and send it to the user\'s email.
         * @summary Send Pr Report
         * @param {File} reportPdf 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSendPrReport: async (reportPdf: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportPdf' is not null or undefined
            assertParamExists('usersSendPrReport', 'reportPdf', reportPdf)
            const localVarPath = `/api/v1/users/send-pr-report`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


            if (reportPdf !== undefined) { 
                localVarFormParams.append('report_pdf', reportPdf as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Confirm user signup and create a new user, while also adding a credit record.
         * @summary Signup Confirmation
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSignupConfirmation: async (token: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('usersSignupConfirmation', 'token', token)
            const localVarPath = `/api/v1/users/signup/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update own password.
         * @summary Update Password Me
         * @param {UpdatePassword} updatePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdatePasswordMe: async (updatePassword: UpdatePassword, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePassword' is not null or undefined
            assertParamExists('usersUpdatePasswordMe', 'updatePassword', updatePassword)
            const localVarPath = `/api/v1/users/me/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update own user.
         * @summary Update User Me
         * @param {UserUpdateMe} userUpdateMe 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUserMe: async (userUpdateMe: UserUpdateMe, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUpdateMe' is not null or undefined
            assertParamExists('usersUpdateUserMe', 'userUpdateMe', userUpdateMe)
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateMe, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Generate PR report PDF and send it to the user\'s email.
         * @summary Generate Pr Report
         * @param {PRReportRequest} pRReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGeneratePrReport(pRReportRequest: PRReportRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGeneratePrReport(pRReportRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersGeneratePrReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get current user.
         * @summary Read User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersReadUserMe(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersReadUserMe(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersReadUserMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Retrieve users.
         * @summary Read Users
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersReadUsers(skip?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsersPublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersReadUsers(skip, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersReadUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Send email confirmation link to create a new user.
         * @summary Register User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersRegisterUser(userCreate: UserCreate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersRegisterUser(userCreate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersRegisterUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Generate PR report PDF and return it directly.
         * @summary Send Pr1
         * @param {PRReportRequest} pRReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersSendPr1(pRReportRequest: PRReportRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersSendPr1(pRReportRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersSendPr1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Receive a PR report PDF from the frontend and send it to the user\'s email.
         * @summary Send Pr Report
         * @param {File} reportPdf 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersSendPrReport(reportPdf: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersSendPrReport(reportPdf, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersSendPrReport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Confirm user signup and create a new user, while also adding a credit record.
         * @summary Signup Confirmation
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersSignupConfirmation(token: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersSignupConfirmation(token, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersSignupConfirmation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update own password.
         * @summary Update Password Me
         * @param {UpdatePassword} updatePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdatePasswordMe(updatePassword: UpdatePassword, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdatePasswordMe(updatePassword, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUpdatePasswordMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update own user.
         * @summary Update User Me
         * @param {UserUpdateMe} userUpdateMe 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdateUserMe(userUpdateMe: UserUpdateMe, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdateUserMe(userUpdateMe, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUpdateUserMe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Generate PR report PDF and send it to the user\'s email.
         * @summary Generate Pr Report
         * @param {PRReportRequest} pRReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGeneratePrReport(pRReportRequest: PRReportRequest, options?: any): AxiosPromise<Message> {
            return localVarFp.usersGeneratePrReport(pRReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get current user.
         * @summary Read User Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersReadUserMe(options?: any): AxiosPromise<UserPublic> {
            return localVarFp.usersReadUserMe(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve users.
         * @summary Read Users
         * @param {number} [skip] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersReadUsers(skip?: number, limit?: number, options?: any): AxiosPromise<UsersPublic> {
            return localVarFp.usersReadUsers(skip, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Send email confirmation link to create a new user.
         * @summary Register User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRegisterUser(userCreate: UserCreate, options?: any): AxiosPromise<Message> {
            return localVarFp.usersRegisterUser(userCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate PR report PDF and return it directly.
         * @summary Send Pr1
         * @param {PRReportRequest} pRReportRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSendPr1(pRReportRequest: PRReportRequest, options?: any): AxiosPromise<any> {
            return localVarFp.usersSendPr1(pRReportRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Receive a PR report PDF from the frontend and send it to the user\'s email.
         * @summary Send Pr Report
         * @param {File} reportPdf 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSendPrReport(reportPdf: File, options?: any): AxiosPromise<Message> {
            return localVarFp.usersSendPrReport(reportPdf, options).then((request) => request(axios, basePath));
        },
        /**
         * Confirm user signup and create a new user, while also adding a credit record.
         * @summary Signup Confirmation
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSignupConfirmation(token: string, options?: any): AxiosPromise<UserPublic> {
            return localVarFp.usersSignupConfirmation(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Update own password.
         * @summary Update Password Me
         * @param {UpdatePassword} updatePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdatePasswordMe(updatePassword: UpdatePassword, options?: any): AxiosPromise<Message> {
            return localVarFp.usersUpdatePasswordMe(updatePassword, options).then((request) => request(axios, basePath));
        },
        /**
         * Update own user.
         * @summary Update User Me
         * @param {UserUpdateMe} userUpdateMe 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUserMe(userUpdateMe: UserUpdateMe, options?: any): AxiosPromise<UserPublic> {
            return localVarFp.usersUpdateUserMe(userUpdateMe, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Generate PR report PDF and send it to the user\'s email.
     * @summary Generate Pr Report
     * @param {PRReportRequest} pRReportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGeneratePrReport(pRReportRequest: PRReportRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersGeneratePrReport(pRReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current user.
     * @summary Read User Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersReadUserMe(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersReadUserMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve users.
     * @summary Read Users
     * @param {number} [skip] 
     * @param {number} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersReadUsers(skip?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersReadUsers(skip, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send email confirmation link to create a new user.
     * @summary Register User
     * @param {UserCreate} userCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersRegisterUser(userCreate: UserCreate, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersRegisterUser(userCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate PR report PDF and return it directly.
     * @summary Send Pr1
     * @param {PRReportRequest} pRReportRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersSendPr1(pRReportRequest: PRReportRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersSendPr1(pRReportRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Receive a PR report PDF from the frontend and send it to the user\'s email.
     * @summary Send Pr Report
     * @param {File} reportPdf 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersSendPrReport(reportPdf: File, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersSendPrReport(reportPdf, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Confirm user signup and create a new user, while also adding a credit record.
     * @summary Signup Confirmation
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersSignupConfirmation(token: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersSignupConfirmation(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update own password.
     * @summary Update Password Me
     * @param {UpdatePassword} updatePassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdatePasswordMe(updatePassword: UpdatePassword, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUpdatePasswordMe(updatePassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update own user.
     * @summary Update User Me
     * @param {UserUpdateMe} userUpdateMe 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdateUserMe(userUpdateMe: UserUpdateMe, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUpdateUserMe(userUpdateMe, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UtilsApi - axios parameter creator
 * @export
 */
export const UtilsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Test emails.
         * @summary Test Email
         * @param {string} emailTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsTestEmail: async (emailTo: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailTo' is not null or undefined
            assertParamExists('utilsTestEmail', 'emailTo', emailTo)
            const localVarPath = `/api/v1/utils/test-email/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (emailTo !== undefined) {
                localVarQueryParameter['email_to'] = emailTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilsApi - functional programming interface
 * @export
 */
export const UtilsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilsApiAxiosParamCreator(configuration)
    return {
        /**
         * Test emails.
         * @summary Test Email
         * @param {string} emailTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsTestEmail(emailTo: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsTestEmail(emailTo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UtilsApi.utilsTestEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UtilsApi - factory interface
 * @export
 */
export const UtilsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilsApiFp(configuration)
    return {
        /**
         * Test emails.
         * @summary Test Email
         * @param {string} emailTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsTestEmail(emailTo: string, options?: any): AxiosPromise<Message> {
            return localVarFp.utilsTestEmail(emailTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilsApi - object-oriented interface
 * @export
 * @class UtilsApi
 * @extends {BaseAPI}
 */
export class UtilsApi extends BaseAPI {
    /**
     * Test emails.
     * @summary Test Email
     * @param {string} emailTo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsApi
     */
    public utilsTestEmail(emailTo: string, options?: RawAxiosRequestConfig) {
        return UtilsApiFp(this.configuration).utilsTestEmail(emailTo, options).then((request) => request(this.axios, this.basePath));
    }
}



