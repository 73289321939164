import styled from "styled-components";
interface StyledContainerProps {
  border?: boolean;
  maxWidth?: number | string;
}
export const StyledContainer = styled("div")<StyledContainerProps>`
  position: relative;
  width: 100%;
  max-width: ${({ maxWidth = 1200 }) => 
    typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth};
  margin-right: auto;
  margin-left: auto;
  padding: 0 60px;

  border-top: ${(p) => (p.border ? "1px solid #CDD1D4" : "")};

  @media only screen and (max-width: 1024px) {
    max-width: calc(100% - 68px);
    padding: 0 30px;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    padding: 0 15px; /* 移动端保留一定的内边距，避免内容贴边 */
  }

  @media only screen and (max-width: 414px) {
    max-width: 100%;
    padding: 0 15px; /* 移动端保留一定的内边距，避免内容贴边 */
  }
`;
