const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: "/tool",
    exact: true,
    component: "Tool",
  },
  {
    path: "/tool/result/:encodedParams",
    exact: true,
    component: "Tool",
  },
  {
    path: "/account",
    exact: true,
    component: "Record",
  },
  {
    path: "/privacy",
    exact: true,
    component: "Privacy",
  },
  {
    path: "/terms",
    exact: true,
    component: "TermsOfService",
  },
  {
    path: "/signup/confirm",
    exact: true,
    component: "SignupConfirm", 
  },
  {
    path: "/reset-password",
    exact: true,
    component: "ResetPassword",
  },
  {
    path: "/tool/detail",
    exact: true,
    component: "Tool",
  },
  {
    path: "/terms-purchase",
    exact: true,
    component: "TermsPurchase",
  }
  
];

export default routes;
