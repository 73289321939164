import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { Configuration } from '../client'; // 导入生成的API客户端配置类
import { message } from 'antd';

interface AuthContextType {
    isAuthenticated: boolean;
    setAccessToken: (token: string | null) => void;
    logout: () => void;
    accessToken: string | null;
    apiClient: Configuration; // 在上下文中添加API客户端配置
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [accessToken, setAccessTokenState] = useState<string | null>(localStorage.getItem('access_token'));
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!accessToken);

    // 从环境变量中获取baseUrl
    const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost';

    // 创建一个API客户端配置实例，并设置baseUrl
    const [apiClient] = useState(() => new Configuration({
        basePath: baseUrl,
        accessToken: accessToken ? `Bearer ${accessToken}` : undefined,
    }));

    useEffect(() => {
        if (accessToken) {
            localStorage.setItem('access_token', accessToken);
            apiClient.accessToken = accessToken;
            
            
            const tokenPayload = JSON.parse(atob(accessToken.split('.')[1]));
            const expirationTime = tokenPayload.exp * 1000; 

            const currentTime = Date.now();
            const timeout = expirationTime - currentTime;

            
            const timer = setTimeout(() => {
                message.warning('Your session has expired. Please log in again.');
                logout();
            }, timeout);


            return () => clearTimeout(timer);
        } else {
            localStorage.removeItem('access_token');
            apiClient.accessToken = undefined;
        }
    }, [accessToken, apiClient]);

    const setAccessToken = (token: string | null) => {
        setAccessTokenState(token);
        setIsAuthenticated(!!token);
    };

    const logout = () => {
        setAccessToken(null);
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, setAccessToken, logout, accessToken, apiClient }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
