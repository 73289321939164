// src/common/Container/index.tsx
import { StyledContainer } from "./styles";
import { ContainerProps } from "../types";

const Container = ({ border, children,maxWidth = 1480 }: ContainerProps) => (
  <StyledContainer border={border} maxWidth={maxWidth}>
    {children}
  </StyledContainer>
);

export default Container;