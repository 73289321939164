import styled, { keyframes } from "styled-components";

// 旋转动画
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// 加载动画样式
export const Loader = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${rotate} 1s linear infinite;
`;

export const StyledButton = styled("button")<{
  color?: string;
  width?: string;
  disabled?: boolean;
}>`
  background: ${(p) => p.color || "#FF5400"};
  color: ${(p) => (p.color ? "#2E186A" : "#fff")};
  font-size: 1rem;
  font-weight: 700;
  width: ${(p) => p.width || "100%"};
  border: 1px solid #edf3f5;
  border-radius: 24px;
  padding: 8px 16px;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  margin-top: 0.625rem;
  
  transition: all 0.3s ease-in-out;
  box-shadow: 0 16px 30px rgb(23 31 114 / 20%);

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    border: 1px solid #FF5400;
    background-color: #FF5400;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
