import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

export const HeaderSection = styled("header")`
    padding: 1rem 0.5rem;
    font-family: 'Motiva Sans Light';

    @media only screen and (max-width: 890px) {
        padding: 1rem 0.5rem;
    }

    .ant-row-space-between {
        align-items: center;
        text-align: center;
    }
`;

export const LogoContainer = styled(Link)`
    display: flex;
    

    @media only screen and (max-width: 890px) {
        margin-left: 15px;
    }
`;

export const NavLink = styled("div")`
    display: inline-block;
    text-align: center;
    vertical-align: middle;
`;

export const CustomNavLink = styled("div")`
    width: 203px;
    display: inline-block;

    @media only screen and (max-width: 411px) {
        width: 150px;
    }

    @media only screen and (max-width: 320px) {
        width: 118px;
    }
`;

export const Burger = styled("div")`
    @media only screen and (max-width: 890px) {
        display: block;
        margin-right: 15px;
    }

    display: none;

    svg {
        fill: #FF5400;
    }
`;

export const NotHidden = styled("div")`
    @media only screen and (max-width: 890px) {
        display: none;
    }
`;

export const Menu = styled("h5")`
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    color: #FF5400;
    @media only screen and (max-width: 890px) {
        display: none;
    }
`;

export const CustomNavLinkSmall = styled(NavLink)`
    font-size: 1.2rem;
    color: #000000;
    margin: 1rem 2rem;
    text-align: center;
    vertical-align: middle;

    @media only screen and (max-width: 890px) {
        margin: 1rem 0;
        width: 100%;
    }

    @media only screen and (max-width: 768px) {
        margin: 1.25rem 0;
    }
`;

export const Label = styled("span")`
    font-weight: 500;
    color: #404041;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

export const Outline = styled(MenuOutlined)`
    font-size: 22px;
`;

export const Span = styled("span")`
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        color: #FF5400;
    }
`;

export const CustomSpan = styled("span")`
    width: 139px;
    height: 63px;
    overflow-wrap: break-word;
    color: #FF5400;
    font-size: 2rem;
    font-family: "Roboto", sans-serif;  /* 修改为 Roboto */
            /* 设置为加粗 */
    text-align: left;
    white-space: nowrap;
    line-height: 63px;
`;


export const DrawerMenu = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const NavItems = styled("div")`
    display: flex;
    align-items: center;
    @media only screen and (max-width: 890px) {
        flex-direction: column;
    }
`;

export const LanguageIconContainer = styled.div`
    @media only screen and (max-width: 890px) {
        margin-left: -16px;
    }
`;